import {EditGuesser, FieldGuesser, ListGuesser, CreateGuesser} from '@api-platform/admin';
import {ReferenceField, TextField, SelectArrayInput, ReferenceInput, AutocompleteInput} from 'react-admin';

const filterToQueryUser = searchText => ({email: searchText});
const filterToQueryOrganization = searchText => ({name: searchText});

export const OrganizationRolesList = props => (<ListGuesser {...props}>
    <ReferenceField label="Organization" source="organization" reference="admin/organizations" link="show">
        <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="User" source="user" reference="admin/users" link="show">
        <TextField source="email" />
    </ReferenceField>
    <FieldGuesser source="roles" />
</ListGuesser>);

export const OrganizationRolesEdit = props => (<EditGuesser {...props}>
    <ReferenceInput source="user" reference="admin/users" allowEmpty alwaysOn>
        <AutocompleteInput label="User email" optionText="email" filterToQuery={filterToQueryUser} />
    </ReferenceInput>
    <ReferenceInput source="organization" reference="admin/organizations" allowEmpty alwaysOn>
        <AutocompleteInput label="Organization" optionText="name" filterToQuery={filterToQueryOrganization} />
    </ReferenceInput>
    <SelectArrayInput source="roles" choices={[
        {
            id: 'ROLE_ORG_ADMIN',
            name: 'Admin'
        },
        {
            id: 'ROLE_ORG_USER',
            name: 'Member'
        }
    ]} />
</EditGuesser>);

export const OrganizationRolesCreate = props => (<CreateGuesser {...props}>
    <ReferenceInput source="user" reference="admin/users" allowEmpty alwaysOn>
        <AutocompleteInput label="User email" optionText="email" filterToQuery={filterToQueryUser} />
    </ReferenceInput>
    <ReferenceInput source="organization" reference="admin/organizations" allowEmpty alwaysOn>
        <AutocompleteInput label="Organization" optionText="name" filterToQuery={filterToQueryOrganization} />
    </ReferenceInput>
    <SelectArrayInput source="roles" choices={[
        {
            id: 'ROLE_ORG_ADMIN',
            name: 'Admin'
        },
        {
            id: 'ROLE_ORG_USER',
            name: 'Member'
        }
    ]} />
</CreateGuesser>);
