import {AppBar, AppBarClasses} from 'react-admin';
import {Box, Typography} from '@mui/material';
import React from 'react';

export const PopmateAppBar = (props) => (<AppBar {...props}>
    <Typography
        variant="h6"
        color="inherit"
        className={AppBarClasses.title}
        id="react-admin-title"
    />
    <Box component="span" sx={{flex: 1}} />
</AppBar>);
