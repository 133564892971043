import {FunctionField} from 'react-admin';

const renderPicture = (record) => {
    if (record.fileName) {
        const briefingId = record.briefing && record.briefing.split('/').pop();
        return <img width="512" src={`${process.env.REACT_APP_API_ENTRYPOINT}/pictures/${briefingId}/${record.fileName}`} alt="" />;
    }

    return null;
};

const renderPicturesListing = (record) => {
    if (!Array.isArray(record.pictures) || record.pictures.length === 0) {
        return <span>No pictures generated for this briefing yet</span>;
    }
    const briefingId = record['@id'] && record['@id'].split('/').pop();

    return (<div>
        {record.pictures.map((picture) => {
            return <div key={picture['@id']}>
                <img width="512" src={`${process.env.REACT_APP_API_ENTRYPOINT}/pictures/${briefingId}/${picture.fileName}`} alt="" />
                <p>{picture.comment}</p>
            </div>;
        })}
    </div>);
};

export const PictureDisplay = (props) => (<FunctionField render={renderPicture} {...props} />);

export const PicturesListing = (props) => (<FunctionField render={renderPicturesListing} {...props} />);
