const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT;

export const fetcher = async(path, options = {}) => {
    const method = options.method || 'GET';
    const data = options.data || null;
    const responseType = options.responseType || 'json';

    const fetchOptions = {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    };

    if (options.headers) {
        fetchOptions.headers = {...fetchOptions.headers, ...options.headers};
    }

    if (method === 'POST' && data) {
        fetchOptions.body = JSON.stringify(data);
    }

    return fetch(`${ENTRYPOINT}${path}`, fetchOptions).then(response => {
        switch (responseType) {
            case 'blob':
                return response.blob();
            default:
                return response.json();
        }
    });
};
