import {ListGuesser, FieldGuesser, EditGuesser, ShowGuesser, InputGuesser, CreateGuesser} from '@api-platform/admin';
import {IdField} from '../components/IdField';

export const OrganizationList = props => (<ListGuesser {...props}>
    <IdField source="id" />
    <FieldGuesser source="name" />
    <FieldGuesser source="creditsAvailable" />
</ListGuesser>);

export const OrganizationShow = props => (<ShowGuesser {...props}>
    <IdField source="id" />
    <FieldGuesser source="name" />
    <FieldGuesser source="billingEmail" />
    <FieldGuesser source="stripeCustomerId" />
    <FieldGuesser source="creditsAvailable" />
    <FieldGuesser source="addressLine1" />
    <FieldGuesser source="addressLine2" />
    <FieldGuesser source="country" />
    <FieldGuesser source="city" />
    <FieldGuesser source="zipCode" />
</ShowGuesser>);

export const OrganizationEdit = props => (<EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="billingEmail" />
    <InputGuesser source="stripeCustomerId" />
    <InputGuesser source="creditsAvailable" />
    <InputGuesser source="addressLine1" />
    <InputGuesser source="addressLine2" />
    <InputGuesser source="country" />
    <InputGuesser source="city" />
    <InputGuesser source="zipCode" />
</EditGuesser>);

export const OrganizationCreate = props => (<CreateGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="billingEmail" />
    <InputGuesser source="creditsAvailable" />
</CreateGuesser>);
