import {FunctionField, useGetList} from 'react-admin';
import {Typography, TableContainer, Table, TableBody, TableRow, TableCell} from '@mui/material';

const renderAnswers = (record, questions) => {
    if (!Array.isArray(record.answers) || record.answers.length === 0) {
        return <span>No answers for this briefing yet</span>;
    }

    const answers = record.answers.map((answer) => {
        if (!answer || !answer.questionSlug || !answer.data) {
            return null;
        }
        const question = questions.find((q) => q.slug === answer.questionSlug);

        let value;
        switch (question.type) {
            case 'table':
            case 'dimension':
                value = (<TableAnswer data={answer.data} answerId={answer.id} />);
                break;
            case 'date-range':
                value = <span>{answer.data[0].value} - {answer.data[1].value}</span>;
                break;
            case 'select-multiple-color':
            case 'checkbox-image':
                value = <span>{answer.data.map((row) => row.value).join(', ')}</span>;
                break;
            case 'conditional-table':
                if (!answer.data[0].value || answer.data[0].value === 'no') {
                    value = <span>No</span>;
                    break;
                }
                value = (<TableAnswer data={answer.data} answerId={answer.id} />);
                break;
            case 'conditional-dimension':
                if (!answer.data[0].value || answer.data[0].value === 'no') {
                    value = <span>No</span>;
                    break;
                }
                if (Array.isArray(answer.data[1].value) && answer.data[1].value.length > 1) {
                    value = <span>{answer.data[1].value.map((dimension) => `${dimension.value} (${dimension.column})`).join(' x ')}</span>;
                    break;
                }
                value = <a href={`${process.env.REACT_APP_API_ENTRYPOINT}/answer/${answer.id}/${answer.data[1].value}`}>{answer.data[1].value}</a>;
                break;
            default:
                value = <span>{answer.data[0].value}</span>;
        }

        console.log(question.type);

        return <Typography variant="body1" key={answer.id}><b>{question.slug}</b>: {value}</Typography>;
    });

    return (<div>
        <Typography variant="body2">Answers</Typography>
        {answers}
    </div>);
};

const TableAnswer = ({
                         data,
                         answerId
                     }) => {
    return (<TableContainer>
        <Table>
            <TableBody>
                {data.map((row, index) => {
                    if (!Array.isArray(row.value)) {
                        return null;
                    }
                    return <TableRow key={`${answerId}-${index}`}>
                        {row.value.map((cell) => {
                            return <TableCell key={cell.column}><b>{cell.column}: {cell.value}</b></TableCell>;
                        })}
                    </TableRow>;
                })}
            </TableBody>
        </Table>
    </TableContainer>);
};

export const Answers = (props) => {
    const {
        data,
        isLoading
    } = useGetList('admin/questions', {
        pagination: {
            page: 1,
            perPage: 100
        }
    });

    if (isLoading) {
        return <span>Loading...</span>;
    }

    return (<FunctionField render={(record) => renderAnswers(record, data)} {...props} />);
};
