import {useState} from 'react';
import {FunctionField, ReferenceField, TextField, Button, useRecordContext, Filter, TextInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import {ListGuesser, FieldGuesser, ShowGuesser, EditGuesser} from '@api-platform/admin';
import {IdField} from '../components/IdField';
import {PicturesListing} from '../components/PictureDisplay';
import {Answers} from '../components/Answer';
import {getTouchPointLabel} from '../utils/briefing';
import {fetcher} from '../utils/fetch';

const filterToQueryUser = searchText => ({email: searchText});

const BriefFilters = (props) => (<Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <ReferenceInput source="user" reference="admin/users" allowEmpty alwaysOn>
        <AutocompleteInput label="User email" optionText="email" filterToQuery={filterToQueryUser} />
    </ReferenceInput>
</Filter>);

export const BriefingList = props => (<ListGuesser
    {...props}
    filters={<BriefFilters />}
    sort={{
        field: 'id',
        order: 'DESC'
    }}>
    <IdField source="id" />
    <FieldGuesser source="name" />
    <ReferenceField label="User email" source="user" reference="admin/users" link="show">
        <TextField source="email" />
    </ReferenceField>
    <FunctionField render={record => record.pictures.length > 0 ? 'Yes' : 'No'} label="Is finished" />
    <FunctionField render={record => getTouchPointLabel(record.touchPoint)} label="Touch point" />
    <FieldGuesser source="createdAt" label="Created on" />
    <FieldGuesser source="updatedAt" label="Last updated on" />
</ListGuesser>);

const ButtonDownloadExport = ({lang}) => {
    const record = useRecordContext();
    const [loading, setLoading] = useState(false);

    if (record.pictures.length === 0) {
        return null;
    }

    const handleClick = async() => {
        setLoading(true);
        if (loading) {
            return true;
        }

        const options = {
            responseType: 'blob',
            headers: {
                'Accept-Language': lang
            }
        };

        const blob = await fetcher(`/briefings/${record.originId}/export`, options);
        const pdfUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.setAttribute('download', `${record.name}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
    };

    const label = lang === 'fr' ? 'Download export in French' : 'Download export in English';

    return (<div>
        <Button onClick={handleClick} label={label}></Button>
        {loading && 'Downloading...'}
    </div>);
};

export const BriefingShow = props => (<ShowGuesser {...props}>
    <IdField source="id" />
    <FieldGuesser source="name" />
    <ReferenceField label="User email" source="user" reference="admin/users" link="show">
        <TextField source="email" />
    </ReferenceField>
    <FunctionField render={record => record.pictures.length > 0 ? 'Yes' : 'No'} label="Is finished" />
    <FunctionField render={record => getTouchPointLabel(record.touchPoint)} label="Touch point" />
    <ButtonDownloadExport lang="fr" />
    <ButtonDownloadExport lang="en" />
    <FieldGuesser source="createdAtDate" label="Created on" />
    <Answers />
    <PicturesListing />
</ShowGuesser>);

export const BriefingEdit = props => (<EditGuesser {...props}>
    <ReferenceInput label="User" source="user" reference="admin/users">
        <AutocompleteInput optionText="email" filterToQuery={filterToQueryUser} />
    </ReferenceInput>
</EditGuesser>);
