import {
    AutocompleteInput,
    Filter,
    ReferenceInput,
    TextInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ReferenceManyField,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    useRecordContext,
    FunctionField
} from 'react-admin';
import {ListGuesser, FieldGuesser, ShowGuesser, CreateGuesser, InputGuesser, EditGuesser} from '@api-platform/admin';
import {IdField} from '../components/IdField';

const filterToQueryOrganization = searchText => ({name: searchText});

const UserFilters = (props) => (<Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="First name" source="firstName" alwaysOn />
    <TextInput label="Last name" source="lastName" alwaysOn />
    <ReferenceInput source="organizations" reference="admin/organizations" allowEmpty alwaysOn>
        <AutocompleteInput label="Organization" optionText="name" filterToQuery={filterToQueryOrganization} />
    </ReferenceInput>
</Filter>);

export const UserList = props => (<ListGuesser
    {...props}
    filters={<UserFilters />}
    sort={{
        field: 'id',
        order: 'DESC'
    }}>
    <IdField source="id" />
    <FieldGuesser source="email" />
    <FieldGuesser source="firstName" />
    <FieldGuesser source="lastName" />
    <ReferenceArrayField label="Organization" source="organizations" reference="admin/organizations" link="show">
        <SingleFieldList>
            <ChipField source="name" />
        </SingleFieldList>
    </ReferenceArrayField>
    <FieldGuesser source="createdAt" label="Created on" />
    <FieldGuesser source="updatedAt" label="Last updated on" />
</ListGuesser>);

const displayRoles = (record) => {
    const roles = [];

    for (const role of record.roles) {
        let roleLabel = '';
        switch (role) {
            case 'ROLE_ORG_ADMIN':
                roleLabel = 'Admin';
                break;
            case 'ROLE_ORG_USER':
                roleLabel = 'Member';
                break;
            default:
                roleLabel = `Unknown (${role})`;
        }
        roles.push(roleLabel);
    }

    return roles.join(', ');
};

export const UserShow = props => (<ShowGuesser {...props}>
    <IdField source="id" />
    <FieldGuesser source="email" />
    <FieldGuesser source="firstName" />
    <FieldGuesser source="lastName" />
    <ReferenceArrayField label="Organization" source="organizations" reference="admin/organizations" link="show">
        <SingleFieldList>
            <ChipField source="name" />
        </SingleFieldList>
    </ReferenceArrayField>
    <FieldGuesser source="roles" />
    <ReferenceManyField reference="admin/organization-roles" target="user" label="Roles" source="originId" sortable={false}>
        <Datagrid>
            <ReferenceField label="Organization" source="organization" reference="admin/organizations" link="show">
                <TextField source="name" />
            </ReferenceField>
            <FunctionField render={displayRoles} />
            <ShowButtonOrganizationRole />
        </Datagrid>
    </ReferenceManyField>
</ShowGuesser>);

const ShowButtonOrganizationRole = (props) => {
    const record = useRecordContext();

    return (<EditButton
        resource="admin/organization-roles"
        record={record}
        label="Edit role"
    ></EditButton>);
};

export const UserEdit = props => (<EditGuesser  {...props}>
    <InputGuesser source="email" />
    <InputGuesser source="firstName" />
    <InputGuesser source="lastName" />
    <ReferenceArrayInput source="organizations" reference="admin/organizations">
        <AutocompleteArrayInput label="Organization" optionText="name" filterToQuery={filterToQueryOrganization} />
    </ReferenceArrayInput>
    <ReferenceManyField reference="admin/organization-roles" target="user" label="Roles" source="originId" sortable={false}>
        <Datagrid>
            <ReferenceField label="Organization" source="organization" reference="admin/organizations" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="roles" />
            <ShowButtonOrganizationRole />
        </Datagrid>
    </ReferenceManyField>
</EditGuesser>);

export const UserCreate = props => (<CreateGuesser {...props}>
    <InputGuesser source="email" />
    <InputGuesser source="firstName" />
    <InputGuesser source="lastName" />
    <InputGuesser source="password" />
    <ReferenceArrayInput source="organizations" reference="admin/organizations">
        <AutocompleteArrayInput label="Organization" optionText="name" filterToQuery={filterToQueryOrganization} />
    </ReferenceArrayInput>
</CreateGuesser>);
