import {defaultTheme} from 'react-admin';

export const popmateTheme = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        primary: {
            contrastText: '#ffffff',
            main: '#4D1ADC',
            light: '#4D1ADC',
            dark: '#4D1ADC'
        },
        secondary: {
            ...defaultTheme.palette.secondary,
            main: '#5b52e4'
        },
        mode: 'light'
    },
    components: {
        ...defaultTheme.components, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore react-admin doesn't add its own components
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderLeft: '3px solid #fff',
                    '&.RaMenuItemLink-active': {
                        borderLeft: '3px solid #00a4ff'
                    }
                }
            }
        }
    }
};
