export const getTouchPointLabel = (touchPoint) => {
    switch (touchPoint) {
        case 'promotional-display':
            return 'Trade marketing multimat';
        case 'shopping-window':
            return 'Shopping window';
        case 'promotional-display-cardboard':
            return 'Trade marketing cardboard';
        case 'counter-display':
            return 'Counter display';
        default:
            return `Unknown touchpoint: ${touchPoint}`;
    }
};
