import {ReferenceField, TextField} from 'react-admin';
import {FieldGuesser, ListGuesser, ShowGuesser} from '@api-platform/admin';
import {IdField} from '../components/IdField';
import {PictureDisplay} from '../components/PictureDisplay';

export const PictureList = props => (<ListGuesser {...props} hasCreate={false}>
    <IdField source="id" />
    <FieldGuesser source="fileName" />
    <ReferenceField label="Briefing name" source="briefing" reference="admin/briefings" link="show">
        <TextField source="name" />
    </ReferenceField>
</ListGuesser>);

export const PictureShow = props => (<ShowGuesser {...props}>
    <IdField source="id" />
    <FieldGuesser source="fileName" />
    <FieldGuesser source="comment" />
    <PictureDisplay />
</ShowGuesser>);
