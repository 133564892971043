import {jwtDecode} from 'jwt-decode';

const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT;

const authProvider = {
    login: ({
                username,
                password
            }) => {
        const request = new Request(`${ENTRYPOINT}/auth`, {
            method: 'POST',
            body: JSON.stringify({
                email: username,
                password
            }),
            headers: new Headers({'Content-Type': 'application/json'})
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({token}) => {
                const requestUser = new Request(`${ENTRYPOINT}/user/me`, {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    })
                });

                return fetch(requestUser).then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                                         .then(({roles}) => {
                                             if (Array.isArray(roles) && roles.includes('ROLE_ADMIN')) {
                                                 localStorage.setItem('token', token);
                                             } else {
                                                 throw new Error('Login failed');
                                             }
                                         });
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            if (!localStorage.getItem('token') || new Date().getTime() / 1000 > // @ts-ignore
                jwtDecode(localStorage.getItem('token'))?.exp) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([
            401,
            403
        ].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve()
};

export default authProvider;
