import {
    fetchHydra as baseFetchHydra, hydraDataProvider as baseHydraDataProvider, useIntrospection
} from '@api-platform/admin';
import {Navigate} from 'react-router-dom';
import {parseHydraDocumentation} from '@api-platform/api-doc-parser';

const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT;

const getHeaders = () => localStorage.getItem('token') ? {
    Authorization: `Bearer ${localStorage.getItem('token')}`
} : {};

const fetchHydra = (url, options = {}) => {
    const headers = getHeaders();

    if (options.method === 'PUT') {
        options.method = 'PATCH';
        headers['Content-Type'] = 'application/merge-patch+json';
    }

    return baseFetchHydra(url, {
        ...options,
        headers
    });
};

const apiDocumentationParser = (setRedirectToLogin) => async() => {
    try {
        setRedirectToLogin(false);

        return await parseHydraDocumentation(ENTRYPOINT, {headers: getHeaders});
    } catch (result) {
        const {
            api,
            response,
            status
        } = result;
        if (status !== 401 || !response) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem('token');

        setRedirectToLogin(true);

        return {
            api,
            response,
            status
        };
    }
};

export const dataProvider = (setRedirectToLogin) => baseHydraDataProvider({
    entrypoint: ENTRYPOINT,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(setRedirectToLogin)
});

export const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem('token')) {
        introspect();
        return <></>;
    }
    return <Navigate to="/login" />;
};
