import {useState} from 'react';
import {Route} from 'react-router-dom';
import {CustomRoutes, Layout, Login} from 'react-admin';
import {HydraAdmin, ResourceGuesser} from '@api-platform/admin';
import authProvider from './utils/authProvider';
import {dataProvider, RedirectToLogin} from './utils/dataProvider';
import {popmateTheme} from './utils/theme';
import {PopmateAppBar} from './components/AppBar';

// Entities
import {UserList, UserShow, UserCreate, UserEdit} from './entity/user';
import {BriefingList, BriefingShow, BriefingEdit} from './entity/briefing';
import {PictureList, PictureShow} from './entity/picture';
import {OrganizationCreate, OrganizationEdit, OrganizationList, OrganizationShow} from './entity/organization';
import {OrganizationRolesCreate, OrganizationRolesEdit, OrganizationRolesList} from './entity/organizationRoles';

const Admin = () => {
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const LoginPage = () => (<Login
        sx={{
            backgroundColor: '#F5F9FF',
            backgroundImage: 'none'
        }}
    />);
    const CustomLayout = (props) => (<Layout appBar={PopmateAppBar} {...props} />);

    if (redirectToLogin) {
        return (<HydraAdmin
            disableTelemetry={true}
            dataProvider={dataProvider(setRedirectToLogin)}
            authProvider={authProvider}
            entrypoint={window.origin}
            theme={popmateTheme}
            loginPage={LoginPage}
            layout={CustomLayout}
        >
            <CustomRoutes>
                <Route path="/" element={<RedirectToLogin />} />
            </CustomRoutes>
        </HydraAdmin>);
    }

    return (<>
        <HydraAdmin
            disableTelemetry={true}
            dataProvider={dataProvider(setRedirectToLogin)}
            authProvider={authProvider}
            entrypoint={window.origin}
            theme={popmateTheme}
            layout={CustomLayout}
            loginPage={LoginPage}
        >
            <ResourceGuesser
                name="admin/users"
                show={UserShow}
                list={UserList}
                create={UserCreate}
                edit={UserEdit}
            />
            <ResourceGuesser
                name="admin/briefings"
                list={BriefingList}
                show={BriefingShow}
                edit={BriefingEdit}
            />
            <ResourceGuesser
                name="admin/organizations"
                list={OrganizationList}
                edit={OrganizationEdit}
                show={OrganizationShow}
                create={OrganizationCreate}
            />
            <ResourceGuesser
                name="admin/pictures"
                list={PictureList}
                show={PictureShow}
            />
            <ResourceGuesser
                name="admin/organization-roles"
                list={OrganizationRolesList}
                edit={OrganizationRolesEdit}
                create={OrganizationRolesCreate}
            />
        </HydraAdmin>
    </>);
};
export default Admin;
